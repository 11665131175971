import {useState, useEffect} from 'react';
import TeamModel from 'models/team.model';

/**
 * Récupère les teams d'une game données
 * @param {number} idGame
 * @param {boolean} autoload 
 * @returns une liste des teams d'une partie
 */
const useTeams = (idGame, autoload = true) => {
	const [teams, setTeams] = useState([]);

	const loadTeams = async () => {
		const data = await TeamModel.getTeamFromGame(idGame);
		setTeams(data);
	};

	useEffect(() => {
		if (autoload) {
			loadTeams();
		}
	}, []);

	return [teams, loadTeams];
};

export default useTeams;