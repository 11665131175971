import {Link} from 'react-router-dom';
import React, {useEffect, useState, useTransition} from 'react';
import PropTypes from 'prop-types';

import GameModel from "../../models/game.model";
import TeamModel from "../../models/team.model";
import useCourse from "../../hooks/useCourse";
import InfoBlockElem from "./InfoBlockElem";
import ScoreTeam from "../stats/ScoreTeam";

const maxTime = 600;

const GameElem = (props) => {

	const game = props.game;
	const [scores, setScores] = useState([]);
	const [course, loadCourse] = useCourse(game.idCourse);
	const [winners, setWinners] = useState([]);


	
	
	const fetchData = async () => {
		try {
			const scoresData = await GameModel.getScores(game.id);
			setScores(scoresData);
			getWinners(scoresData)
		}catch (e) {
			console.log('erreur fetchData',e);
		}
	}
	
	useEffect(() => {
		fetchData();
	}, []);
	
	
	/**
	 * Retourne la team gagnante de la partie
	 * @param scoresData
	 * @returns {*}
	 */
	const getWinners = async (scoresData) => {
		try {
			let maxScore = scoresData==null ?scores[0] : scoresData[0];
			
			const scoreTeam = new Map();

			scores.forEach((score) => {
				const calculatedScore = (score.isSolved ? 500 : 0) +
					(score.nbGoodAnswers * 100) -
					(score.nbHints * 20) -
					(score.nbBadAnswers * 10);
				addOrUpdateScore(ScoreTeam, score.idTeam, calculatedScore);
			});
			getTeamWithMaxScore(scoreTeam, maxScore);
			const data = await TeamModel.getTeam(maxScore.idTeam);
			setWinners(data);
		}catch (e) {
			console.log('erreur getWinners',e);
		}
	}
	const addOrUpdateScore = (scoreTeam, idTeam, score) => {
		if (scoreTeam.has(idTeam)) {
			const currentScore = scoreTeam.get(idTeam);
			scoreTeam.set(idTeam, currentScore + score);
		} else {
			scoreTeam.set(idTeam, score);
		}
	};
	const getTeamWithMaxScore = (scoreTeam, firstKey) => {
		let result = firstKey.idTeam;
		let firstValue = scoreTeam.get(firstKey.idTeam)
		//recherche du score max dans la map
		scoreTeam.forEach((value, key) => {
			if (value > firstValue) {
				firstValue = value;
				result= key;
			}
		});
		return result;
	}
	
	
	const getWinRate = () => {
		if (scores == null) return 0;
		let winRate = 0;
		const nbScore = scores.length;
		if(scores.length !== 0) {
			scores.forEach((score) => {
				score.isSolved && winRate++;
			});
			return Math.floor((winRate / nbScore) * 100);
		} else {
			return 0;
		}
	}
	if (course == null) return <p>Loading</p>
	if (winners == null) return <p>Loading</p>

	let winnerLabel ;
	let successRateLabel ;
	let link ;
	switch (game.state) {
		case 0:
			winnerLabel = "Partie non lancée" ;
			successRateLabel = "Partie non lancée" ;
			link =  <Link to={'/pregame/'+game.gameCode} className="btn-show col-span-2">lancer</Link>;
			break ;
		case 1:
			winnerLabel = "Partie en cours" ;
			successRateLabel = "Partie en cours" ;
			link =  <Link to={`/leaderboard?sessionId=${game.gameCode}`} className="btn-show col-span-2">Voir</Link>;
			break ;
		case 2:
			winnerLabel = winners.name ;
			successRateLabel = getWinRate()+'%' ;
			link =  <Link to={'/ranking/'+game.id} className="btn-show col-span-2">Voir</Link>;
			break ;
	}
	return (
		// Affichage des informations de la partie
		// Grid pour afficher les informations sur 2 colonnes fixes
		<article className='info-block grid-block'>

			<InfoBlockElem title='Nom' text={game.name} additionalClasses='col-span-2 pt-2' />

			<InfoBlockElem title='Classe' text={course.name}/>


			<InfoBlockElem title='Date' text={game.createdAt.toLocaleDateString('fr-Fr')} />

			<InfoBlockElem title='Gagnants' text={winnerLabel} />

			<InfoBlockElem title='Taux de réussite' text={successRateLabel} />
			{link}
		</article>
	)
}

GameElem.propTypes = {
	game: PropTypes.object.isRequired
}

export default GameElem;